import React from "react";
import { IAuthenticationAPI } from "../authentication";
import { IPortfoliosAPI } from "../portfolios";

export interface APIProvider {
    authenticationAPI: IAuthenticationAPI
    portfoliosAPI: IPortfoliosAPI
    isMock: boolean
}

export interface IAPIContext extends Partial<APIProvider> { }
export const APIContext = React.createContext<IAPIContext>({});

export const APIContextProvider: React.FC<
    APIProvider & {
        children?: React.ReactNode;
    }
> = ({ children, authenticationAPI, portfoliosAPI, isMock }) => {
    return (
        <APIContext.Provider
            value={{
                authenticationAPI: authenticationAPI,
                portfoliosAPI: portfoliosAPI,
                isMock: isMock
            }}
        >
            {children}
        </APIContext.Provider>
    )
}

export const useAPI = () => {
    return React.useContext(APIContext);
};