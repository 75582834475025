export function convertToDate(input: string): Date {
    return new Date(+input * 1000)
}

export function convertToUnixTimestamp(input: Date): string {
    return (Math.floor(input.getTime() / 1000)).toString()
}

export function capitalize(input: string) {
    return input[0].toUpperCase() + input.slice(1).toLowerCase();
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    if (value === null || value === undefined) return false;
    const testDummy: TValue = value;
    return true;
}