import { useAPI } from "@bankingright-web/api";
import { MessageDeliveryMethod } from "@bankingright-web/enums";
import { IAllowedMessageDeliveryMethod } from "@bankingright-web/interfaces";
import { VStack } from "@chakra-ui/react";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { ChooseMessageDeliveryMethod } from "./choose-message-delivery-method";
import { ProvideMessageVerificationCode } from "./provide-message-verification-code";

interface MessageVerificationCodeProps {
    deviceId: string
    deliveryMethodToken?: string
    loginRequestId: string
    didProvideVerificationCode: () => void
}

interface DeliveryDetails {
    deliveryMethod: string
    hint: string
    length: number
    expiresAt: string
}

export const MessageVerificationCode: React.FC<MessageVerificationCodeProps> = ({
    deviceId,
    deliveryMethodToken,
    loginRequestId,
    didProvideVerificationCode
}) => {

    const { authenticationAPI } = useAPI()
    const [mode, setMode] = useState<DeliveryDetails>();
    const [nonce, setNonce] = useState(uuidv4().slice(0, 8))


    const didChooseDeliveryMethod = (deliveryMethod: MessageDeliveryMethod, hint: string, length: number, expiresAt: string) => {
        setMode({
            deliveryMethod: deliveryMethod,
            hint: hint,
            length: length,
            expiresAt: expiresAt
        })
    }

    const messageDeliveryFlow = () => {
        switch (mode?.deliveryMethod) {
            case MessageDeliveryMethod.email:
            case MessageDeliveryMethod.sms:
                return (
                    <ProvideMessageVerificationCode
                        method={mode.deliveryMethod}
                        hint={mode.hint}
                        length={mode.length}
                        nonce={nonce}
                        loginRequestId={loginRequestId}
                        didProvideVerificationCode={didProvideVerificationCode}
                    />
                )
            default:
                return (
                    <ChooseMessageDeliveryMethod
                        deviceId={deviceId}
                        deliveryMethodToken={deliveryMethodToken}
                        nonce={nonce}
                        didChooseDeliveryMethod={didChooseDeliveryMethod}
                    />
                )
        }
    }

    return (
        <VStack mt={5} gap={5} align="stretch">
            {messageDeliveryFlow()}
        </VStack>
    )
};