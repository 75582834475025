import { useAPI } from "@bankingright-web/api";
import { IAllowedMessageDeliveryMethod, IBankingRightError } from "@bankingright-web/interfaces";
import { Button, FormControl, FormLabel, Heading, Input, Text, VStack, Flex } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useState } from "react"
import { useNotification } from "@bankingright-web/ui";
import { MessageDeliveryMethod, VerificationCodeMethod } from "@bankingright-web/enums";
import { useTranslate } from "@bankingright-web/core";

interface ProvideMessageVerificationCodeProps {
    method: MessageDeliveryMethod
    hint: string
    length: number
    nonce: string
    loginRequestId: string
    didProvideVerificationCode: () => void
}

type Inputs = {
    code: string
};

export const ProvideMessageVerificationCode: React.FC<ProvideMessageVerificationCodeProps> = ({ method, hint, length, nonce, loginRequestId, didProvideVerificationCode }) => {

    const { register, handleSubmit, reset, formState: { errors } } = useForm<Inputs>();
    const { authenticationAPI } = useAPI()
    const [loading, setLoading] = useState(false)
    const { open } = useNotification()
    const translate = useTranslate()

    const onSubmit = (input: Inputs) => {
        setLoading(true)
        authenticationAPI?.verifyLoginRequestVerificationCode({
            loginRequestId: loginRequestId,
            verificationCode: input.code,
            verificationCodeMethod: VerificationCodeMethod.message,
            nonce: nonce
        }).then(() => {
            didProvideVerificationCode()
        }).catch((error: IBankingRightError) => {
            open?.({
                message: "Error",
                description: error.userMessage,
                type: "error"
            })
            reset()
        }).finally(() => {
            setLoading(false)
        })
    }

    const hintDescription = () => {
        switch (method) {
            case MessageDeliveryMethod.email:
                return translate("message_verification_code_label_hint_email", { hint: hint }, "We've sent you a verification code via email on: {{hint}}")
            case MessageDeliveryMethod.sms:
                return translate("message_verification_code_label_hint_sms", { hint: hint }, "We've sent you a verification code in a text message on: {{hint}}")
            default:
                return "Something went wrong"
        }
    }

    return (
        <Flex
            direction="column"
            mb={10}
        >
            <Text>{hintDescription()}</Text>

            <form onSubmit={handleSubmit(onSubmit)}>
                <VStack mt={8}>
                    <FormControl>
                        <FormLabel>Verification code</FormLabel>
                        <Input
                            {...register("code", { required: true, maxLength: 20 })}
                            type="text"
                            placeholder={translate("message_verification_code_inputfield_placeholder", { length: length }, "Provide code here...")}
                        />
                        {errors.code && <Text size="sm" color="red">This field is required</Text>}
                    </FormControl>
                </VStack>
                <Button mt={5} type="submit" isLoading={loading}>Submit</Button>
            </form>

        </Flex>
    )
};