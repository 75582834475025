import { ILoginRequestResponse } from "@bankingright-web/interfaces";
import { Flex, Heading, HStack, Text } from "@chakra-ui/react";
import { LoginWithCredentials } from "./login-with-credentials";
import { LoginWithQRCode } from "./login-with-qr-code";

interface ChooseLoginMethodProps {
    deviceId: string
    didLoginWithCredentials: (loginRequest: ILoginRequestResponse) => void
    didLoginWithQRCode: () => void
    didDirectlyLoginWithCredentials: () => void
}

export const ChooseLoginMethod: React.FC<ChooseLoginMethodProps> = ({
    deviceId,
    didLoginWithCredentials,
    didLoginWithQRCode,
    didDirectlyLoginWithCredentials
}) => {
    return (
        <Flex
            direction="column"
            mb={10}
        >
            <Text>Please choose below from one of the two methods of login.</Text>
            <HStack gap={10} mt={10} align="flex-start">
                <Flex direction="column" align="center">
                    <Heading size="md" pb={5}>Credentials</Heading>
                    <LoginWithCredentials
                        deviceId={deviceId}
                        didLoginWithCredentials={didLoginWithCredentials}
                        didDirectlyLoginWithCredentials={didDirectlyLoginWithCredentials}
                    />
                </Flex>
                <Flex direction="column" align="center">
                    <Heading size="md" pb={5}>QR Code</Heading>
                    <LoginWithQRCode didLoginWithQRCode={didLoginWithQRCode} />
                </Flex>
            </HStack>
        </Flex>
    )
};