import {
    IPortfoliosResponse  
} from "@bankingright-web/interfaces";
import { AxiosInstance } from "axios";

export interface IPortfoliosAPI {
    portfolios: (includeAccounts: boolean) => Promise<IPortfoliosResponse>
}

export const PortfoliosAPI = (httpClient: AxiosInstance): IPortfoliosAPI => {
    return {
        portfolios: async function (includeAccounts: boolean) {
            const response = await httpClient.request<IPortfoliosResponse>({
                url: `/v1/portfolios`,
                method: "GET",
                params: {
                    include_accounts: includeAccounts
                }
            })

            return response.data
        }
    }
}
