import {
    ICreateLoginRequest,
    ILoginRequestDevicelessResponse,
    ILoginRequestResponse,
    ILoginRequestSigningTask,
    ILoginRequestStatus,
    ILoginRequestLoggedInResponse,
    IMessageVerificationCodeDeliveryMethodsResponse,
    IRequestVerificationCode,
    IRequestVerificationCodeResponse,
    IVerifyLoginRequestVerificationCode,
    ILoginRequestDevicelessLoggedInResponse,
    ILoginRequest,
    ILoginResponse    
} from "@bankingright-web/interfaces";
import { AxiosInstance } from "axios";

export interface IAuthenticationAPI {
    login: (params: ILoginRequest) => Promise<ILoginResponse>
    createLoginRequest: (params: ICreateLoginRequest) => Promise<ILoginRequestResponse | ILoginRequestLoggedInResponse>
    createLoginRequestDeviceless: () => Promise<ILoginRequestDevicelessResponse>
    getMessageVerificationCodeDeliveryMethods: (deviceId: string, deliveryMethodToken?: string) => Promise<IMessageVerificationCodeDeliveryMethodsResponse>
    requestMessageVerificationCode: (params: IRequestVerificationCode) => Promise<IRequestVerificationCodeResponse>
    verifyLoginRequestVerificationCode: (params: IVerifyLoginRequestVerificationCode) => Promise<void>
    checkLoginRequestStatus: (params: ILoginRequestStatus) => Promise<ILoginRequestLoggedInResponse>
    checkLoginRequestDevicelessStatus: (params: ILoginRequestStatus) => Promise<ILoginRequestDevicelessLoggedInResponse>
    requestLoginRequestSigningTask: (params: ILoginRequestSigningTask) => Promise<void>
}

export const AuthenticationAPI = (httpClient: AxiosInstance): IAuthenticationAPI => {
    return {
        login: async function (request: ILoginRequest) {
            const response = await httpClient.request<ILoginResponse>({
                url: `/v1/authentication/otp/login`,
                method: "POST",
                data: request,
            })

            return response.data
        },
        createLoginRequest: async function (request: ICreateLoginRequest) {
            const response = await httpClient.request<ILoginRequestResponse>({
                url: `/v1/authentication/secureright/loginrequest`,
                method: "POST",
                data: request,
            })

            return response.data
        },
        createLoginRequestDeviceless: async function () {
            const response = await httpClient.request<ILoginRequestDevicelessResponse>({
                url: `/v1/authentication/secureright/loginrequest/deviceless`,
                method: "POST"
            })

            return response.data
        },
        getMessageVerificationCodeDeliveryMethods: async function (deviceId: string, deliveryMethodToken?: string) {
            const response = await httpClient.request<IMessageVerificationCodeDeliveryMethodsResponse>({
                url: `/v1/devices/${deviceId}/verificationcode/message/deliverymethods`,
                method: "GET",
                params: {
                    delivery_method_token: deliveryMethodToken
                }
            })

            return response.data
        },
        requestMessageVerificationCode: async function (request: IRequestVerificationCode) {
            const response = await httpClient.request<IRequestVerificationCodeResponse>({
                url: `/v1/devices/${request.deviceId}/verificationcode/message/request`,
                method: "POST",
                data: request,
            })

            return response.data
        },
        verifyLoginRequestVerificationCode: async function (request: IVerifyLoginRequestVerificationCode) {
            const response = await httpClient.request<void>({
                url: `/v1/authentication/secureright/loginrequest/verify`,
                method: "POST",
                data: request,
            })

            return response.data
        },
        checkLoginRequestStatus: async function (request: ILoginRequestStatus) {
            const response = await httpClient.request<ILoginRequestLoggedInResponse>({
                url: `/v1/authentication/secureright/loginrequest/status`,
                method: "POST",
                data: request,
            })

            return response.data
        },
        checkLoginRequestDevicelessStatus: async function (request: ILoginRequestStatus) {
            const response = await httpClient.request<ILoginRequestDevicelessLoggedInResponse>({
                url: `/v1/authentication/secureright/loginrequest/deviceless/status`,
                method: "POST",
                data: request,
            })

            return response.data
        },
        requestLoginRequestSigningTask: async function (request: ILoginRequestSigningTask) {
            const response = await httpClient.request<void>({
                url: `/v1/authentication/secureright/loginrequest/signingtask`,
                method: "POST",
                data: request,
            })

            return response.data
        }
    }
}
