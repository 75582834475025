import React, { useMemo } from "react";
import { ReactNode, useContext } from "react";

export type Translate = (
    key: string,
    options?: any,
    defaultMessage?: string,
) => string;

export interface I18nProvider {
    translate: Translate;
    changeLocale: (locale: string, options?: any) => Promise<any>;
    getLocale: () => string;
}

export interface ITranslationContext {
    i18nProvider?: I18nProvider;
}

export const defaultProvider: ITranslationContext = {};

export const TranslationContext = React.createContext<ITranslationContext>({});

export const TranslationContextProvider: React.FC<
    ITranslationContext & {
        children?: React.ReactNode;
    }
> = ({ children, i18nProvider }) => {    
    return (
        <TranslationContext.Provider
            value={{
                i18nProvider: i18nProvider,
            }}
        >
            {children}
        </TranslationContext.Provider>
    );
};


export type TranslationProvider = Required<ITranslationContext>;

export const useTranslate = () => {
    const { i18nProvider } = useContext(TranslationContext);

    const fn = useMemo(() => {
        function translate(
            key: string,
            options?: any,
            defaultMessage?: string,
        ): string;
        function translate(key: string, defaultMessage?: string): string;

        function translate(
            key: string,
            options?: string | any,
            defaultMessage?: string,
        ) {
            return (
                i18nProvider?.translate(key, options, defaultMessage) ??
                defaultMessage ??
                (typeof options === "string" &&
                typeof defaultMessage === "undefined"
                    ? options
                    : key)
            );
        }

        return translate;
    }, [i18nProvider]);

    return fn;
};
