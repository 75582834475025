import { AccountOverview } from "@bankingright-web/accounts";
import { BankingRightAPIProvider } from "@bankingright-web/api";
import { BankingRightAuthProvider } from "@bankingright-web/auth-provider";
import { BankingRight, ProtectedRoute } from "@bankingright-web/core";
import { Login } from "@bankingright-web/login";
import { NotFound } from "@bankingright-web/pages";
import { BankingRightNotificationProvider } from "@bankingright-web/ui";
import React from "react";
import { useTranslation } from "react-i18next";
import { Route, Routes } from 'react-router-dom';

const App: React.FC = () => {

    const { t, i18n } = useTranslation();
    const i18nProvider = {
        translate: (key: string, params: object) => t(key, params),
        changeLocale: (lang: string) => i18n.changeLanguage(lang),
        getLocale: () => i18n.language,
    };

    const notificationProvider = BankingRightNotificationProvider()
    const authProvider = BankingRightAuthProvider({})
    const apiProvider = BankingRightAPIProvider({
        baseUrl: "https://api-t.bankingright.com/api",
        // baseUrl: "https://private-2ad51-bankingrightmobileapi.apiary-mock.com",
        authProvider: authProvider,
        notificationProvider: notificationProvider,
        i18nProvider: i18nProvider,
        // isMock: true
    })

    return (
        <BankingRight
            notificationProvider={notificationProvider}
            apiProvider={apiProvider}
            authProvider={authProvider}
            i18nProvider={i18nProvider}
        >
            <Routes>
                <Route index element={
                    <ProtectedRoute>
                        <AccountOverview />
                    </ProtectedRoute>
                } />
                <Route path="login" element={<Login />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BankingRight>
    );
}

export default App;
