import { IAllowedMessageDeliveryMethod, IBankingRightError } from "@bankingright-web/interfaces";
import { MessageDeliveryMethod } from "@bankingright-web/enums"
import { Box, Flex, Heading, Spinner, Text, VStack } from "@chakra-ui/react";
import { FiAtSign, FiSmartphone, FiAlertCircle } from "react-icons/fi"
import { useAPI } from "@bankingright-web/api";
import { useState, useEffect } from "react"
import { useNotification } from "@bankingright-web/ui";
import { useTranslate } from "@bankingright-web/core";

interface ChooseMessageDeliveryMethodsProps {
    deviceId: string
    deliveryMethodToken?: string
    nonce: string
    didChooseDeliveryMethod: (deliverMethod: MessageDeliveryMethod, hint: string, length: number, expiresAt: string) => void
}

interface DeliveryMethodInfo {
    title: string
    description: string
    icon: React.ReactNode
}

export const ChooseMessageDeliveryMethod: React.FC<ChooseMessageDeliveryMethodsProps> = ({
    deviceId,
    deliveryMethodToken,
    nonce,
    didChooseDeliveryMethod
}) => {

    const { authenticationAPI } = useAPI()
    const [deliveryMethods, setDeliveryMethods] = useState(Array<IAllowedMessageDeliveryMethod>());
    const [loading, setLoading] = useState(false)
    const { open } = useNotification()
    const translate = useTranslate();

    useEffect(() => {
        setLoading(true)
        const fetchMethods = async () => {
            const data = await authenticationAPI?.getMessageVerificationCodeDeliveryMethods(deviceId, deliveryMethodToken);
            if (data?.allowedDeliveryMethods) {
                setDeliveryMethods(data.allowedDeliveryMethods)
            }
            setLoading(false)
        }

        fetchMethods()
    }, [])

    const onChooseDeliveryMethod = (method: IAllowedMessageDeliveryMethod) => {
        setLoading(true)
        authenticationAPI?.requestMessageVerificationCode({
            deviceId: deviceId,
            nonce: nonce,
            deliveryMethodToken: deliveryMethodToken,
            preferredDeliveryMethod: method.deliveryMethod
        }).then((response) => {
            didChooseDeliveryMethod(response.deliveryMethod, response.hint, response.length, response.expiresAt)
        }).catch((error: IBankingRightError) => {
            open?.({
                message: "Error",
                description: error.userMessage,
                type: "error"
            })
        }).finally(() => {
            setLoading(false)
        })
    }

    const deliveryMethodInfo: (method: MessageDeliveryMethod, hint: string) => DeliveryMethodInfo = (method, hint) => {
        switch (method) {
            case MessageDeliveryMethod.email:
                return ({
                    title: translate("message_verification_code_method_button_title_email", "Send an email"),
                    description:  translate("message_verification_code_method_button_subtitle_email", {hint: hint}, "You will receive a verification code per email on: "),
                    icon: <FiAtSign size={25} />
                })
            case MessageDeliveryMethod.sms:
                return ({
                    title: translate("message_verification_code_method_button_title_sms", {hint: hint}, "Send a SMS"),
                    description: "You will receive per sms on: " + hint,
                    icon: <FiSmartphone size={25} />
                })
            default:
                return ({
                    title: "Error",
                    description: "Something went wrong",
                    icon: <FiAlertCircle size={25} />
                })
        }
    }

    return (
        <Flex
            direction="column"
            mb={10}
            h="100%"
        >
            <Text>{translate("message_verification_code_method_label_title", "How do you want to receive your verification code?")}</Text>
            <VStack
                mt={5}
                spacing={3}
                h="100%"
            >
                {loading &&
                    <Flex
                        h="100%"
                        align="center"
                        justify="center"
                    >
                        <Spinner
                            thickness='4px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='blue.500'
                            size='xl'
                        />
                    </Flex>
                }
                {!loading && deliveryMethods.map((method: IAllowedMessageDeliveryMethod, index: number) => {

                    const info = deliveryMethodInfo(method.deliveryMethod, method.hint)

                    return (
                        <Flex
                            key={index}
                            align="center"
                            border="2px"
                            w="100%"
                            borderColor="gray.100"
                            minH="80px"
                            onClick={() => onChooseDeliveryMethod(method)}
                            _hover={{
                                background: "chakra-subtle-bg",
                                cursor: "pointer",
                            }}
                        >
                            <Box p={3}>
                                {info.icon}
                            </Box>
                            <Flex direction="column" ml={1}>
                                <Heading size="sm">{info.title}</Heading>
                                <Text fontSize="sm" maxW="400px">{info.description}</Text>
                            </Flex>
                        </Flex>
                    )
                })}
            </VStack>
        </Flex>
    )
};