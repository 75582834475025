import { useAPI } from "@bankingright-web/api"
import { VerificationCodeMethod } from "@bankingright-web/enums"
import { useInterval } from "@bankingright-web/utils"
import { Button, Flex, Spinner, Text, VStack } from "@chakra-ui/react"
import { useState } from "react"
import { FiAlertTriangle } from 'react-icons/fi'

interface LoginRequestStatusProps {
    loginRequestId: string
    verificationCodeMethod: VerificationCodeMethod
    didSuccessfullyLogin: () => void
    didFailLogin: () => void
}

export const LoginRequestStatus: React.FC<LoginRequestStatusProps> = ({
    loginRequestId,
    verificationCodeMethod,
    didSuccessfullyLogin,
    didFailLogin
}) => {

    const [polling, setPolling] = useState<boolean>(true)
    const [expired, setExpired] = useState<boolean>(false)

    const { authenticationAPI } = useAPI()

    const description = verificationCodeMethod == VerificationCodeMethod.signingTask ?
        "Login with your app to confirm your login request." :
        "Your login request is being verified."

    useInterval(() => {
        authenticationAPI?.checkLoginRequestStatus({
            loginRequestId: loginRequestId
        }).then((response) => {
            if (response.onboardingStatus != undefined) {
                didSuccessfullyLogin()
            }
        }).catch((error) => {
            setExpired(true)
            setPolling(false)
        })
    }, polling ? 1000 * 2 : null)

    return (
        <Flex
            direction="column"
            w="100%"
        >
            <Text>{description}</Text>
            <Flex
                direction="column"
                h="100%"
                align="center"
                justify="center"
            >
                {!expired &&
                    <Spinner
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='gray.200'
                        color='blue.500'
                        size='xl'
                    />
                }
                {expired &&
                    <VStack bg="red.600" p={5} rounded="2xl">
                        <FiAlertTriangle size={30} color="white" />
                        <Text textColor="white">Your login request is expired</Text>
                        <Button onClick={didFailLogin}>Try again</Button>
                    </VStack>
                }
            </Flex>
        </Flex>
    )
}