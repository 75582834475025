import { useAuth } from '@bankingright-web/auth-provider';
import {
    Avatar, Box, BoxProps, CloseButton, Drawer,
    DrawerContent, Flex, FlexProps, HStack, IconButton, Link, Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList, Text, useColorModeValue, useDisclosure, VStack
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { FiChevronDown, FiHome, FiMenu } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';

interface LayoutProps extends BoxProps {
    children?: React.ReactNode
}

export interface MenuItem {
    label: string
    route?: string
    icon: React.ReactNode
}

const menuItems: Array<MenuItem> = [
    {
        label: "Dashboard",
        route: "/",
        icon: <FiHome />
    }
]

export const Layout: React.FC<LayoutProps> = ({
    children
}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
            <>
                <SidebarContent
                    onClose={() => onClose}
                    display={{ base: 'none', md: 'block' }}
                />
                <Drawer
                    autoFocus={false}
                    isOpen={isOpen}
                    placement="left"
                    onClose={onClose}
                    returnFocusOnClose={false}
                    onOverlayClick={onClose}
                    size="full">
                    <DrawerContent>
                        <SidebarContent onClose={onClose} />
                    </DrawerContent>
                </Drawer>
                {/* mobilenav */}
                <MobileNav onOpen={onOpen} />
            </>

            <Box ml={{ base: 0, md: 60 }} p="4" >
                {children}
            </Box>
        </Box>
    );
}

interface SidebarProps extends BoxProps {
    onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {

    const [selectedKey, setSelectedKey] = useState("")

    const renderNavItems = (tree: Array<MenuItem>, selectedKey: string, onClose: () => void) => {
        return tree.map((item: MenuItem) => {
            const { icon, label, route } = item;
            const isSelected = route === selectedKey;

            return (
                <NavItem key={selectedKey} route={route} isSelected={isSelected} icon={icon} onClick={onClose}>
                    {label}
                </NavItem>
            )
        })
    }

    return (
        <Box
            bg={useColorModeValue('white', 'gray.900')}
            borderRight="1px"
            borderRightColor="chakra-border-color"
            w={{ base: 'full', md: 60 }}
            pos="fixed"
            h="full"
            {...rest}>
            <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
                <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
                    BankingRight
                </Text>
                <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
            </Flex>
            {renderNavItems(menuItems, selectedKey, onClose)}
        </Box>
    )
};

interface NavItemProps extends FlexProps {
    icon: React.ReactNode;
    route?: string
    isSelected: Boolean
}

const NavItem = ({ icon, route, isSelected, children, ...rest }: NavItemProps) => {

    return (
        <Link as={NavLink} to={route ?? "#"} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
            <Flex
                align="center"
                p="4"
                mx="4"
                borderRadius="lg"
                role="group"
                cursor="pointer"
                _hover={{
                    bg: "chakra-subtle-bg",
                    color: "chakra-body-text",
                }}
                {...rest}>
                {icon && (
                    <Box
                        mr="4"
                        fontSize="16"
                        _groupHover={{
                            color: "chakra-body-text",
                        }}>
                        {icon}
                    </Box>
                )}
                <Box fontWeight={isSelected ? "bold" : "normal"}>
                    {children}
                </Box>
            </Flex>
        </Link>
    );
};

interface MobileProps extends FlexProps {
    onOpen: () => void;
}

const MobileNav = ({ onOpen, ...rest }: MobileProps) => {

    const { logout, checkAuth } = useAuth()
    const isAuthenticated = checkAuth?.() ?? false
    const settings = () => {
        // nothing
    }

    return (
        <Flex
            ml={{ base: 0, md: 60 }}
            px={{ base: 4, md: 4 }}
            height="20"
            alignItems="center"
            bg={useColorModeValue('white', 'gray.900')}
            borderBottomWidth="1px"
            borderBottomColor="chakra-border-color"
            justifyContent={{ base: 'space-between', md: 'flex-end' }}
            {...rest}>
            <IconButton
                display={{ base: 'flex', md: 'none' }}
                onClick={onOpen}
                variant="outline"
                aria-label="open menu"
                icon={<FiMenu />}
            />

            <Text
                display={{ base: 'flex', md: 'none' }}
                fontSize="2xl"
                fontFamily="monospace"
                fontWeight="bold">
                BankingRight
            </Text>

            {isAuthenticated &&
                <HStack spacing={{ base: '3', md: '6' }}>
                    <Flex alignItems={'center'}>
                        <Menu>
                            <MenuButton
                                py={2}
                                transition="all 0.3s"
                                _focus={{ boxShadow: 'none' }}>
                                <HStack>
                                    <Avatar
                                        name="Unknown"
                                        bg='teal.500'
                                        size={'sm'}
                                    />
                                    <VStack
                                        display={{ base: 'none', md: 'flex' }}
                                        alignItems="flex-start"
                                        spacing="1px"
                                        ml="2">
                                        <Text fontSize="sm">Username</Text>
                                    </VStack>
                                    <Box display={{ base: 'none', md: 'flex' }}>
                                        <FiChevronDown />
                                    </Box>
                                </HStack>
                            </MenuButton>
                            <MenuList>
                                <MenuItem onClick={settings} >Settings</MenuItem>
                                <MenuDivider />
                                <MenuItem onClick={logout} >Logout</MenuItem>
                            </MenuList>
                        </Menu>
                    </Flex>
                </HStack>
            }
        </Flex>
    );
};