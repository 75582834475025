import { useAPI } from "@bankingright-web/api";
import { ResponseMethod } from "@bankingright-web/enums";
import { IBankingRightError, ILoginRequestDevicelessLoggedInResponse, ILoginRequestDevicelessResponse } from "@bankingright-web/interfaces";
import { useInterval } from "@bankingright-web/utils";
import { Box, Flex, IconButton, Spinner, useColorModeValue } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FiRefreshCw, FiAlertTriangle } from "react-icons/fi";
import QRCode from "react-qr-code";

interface LoginWithQRCodeProps {
    didLoginWithQRCode: () => void
}

export const LoginWithQRCode: React.FC<LoginWithQRCodeProps> = ({
    didLoginWithQRCode
}) => {

    const [loading, setLoading] = useState<boolean>(false)
    const [loginRequestDeviceless, setLoginRequestDeviceless] = useState<ILoginRequestDevicelessResponse>();
    const { authenticationAPI, isMock } = useAPI()
    const [polling, setPolling] = useState<boolean>(false)
    const [pollingCount, setPollingCount] = useState<number>(0)
    const [expired, setExpired] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)
    const maxPollingCount = 10

    useEffect(() => {
        fetchLoginRequestDeviceless()
    }, [])

    const fetchLoginRequestDeviceless = () => {
        setLoginRequestDeviceless(undefined)
        setLoading(true)

        authenticationAPI?.createLoginRequestDeviceless()
            .then((response) => {
                setLoginRequestDeviceless(response)

                if (!(isMock ?? false)) {
                    setPolling(true)
                    setExpired(false)
                    setPollingCount(0)
                }

            }).catch((error: IBankingRightError) => {
                setError(true)
            }).finally(() => {
                setLoading(false)
            });
    }

    const login = (params: ILoginRequestDevicelessLoggedInResponse) => {
        setLoginRequestDeviceless(undefined)
        setLoading(true)

        authenticationAPI?.login({
            userName: params.userId,
            deviceId: params.deviceId,
            challenge: params.challenge,
            response: params.response,
            responseMethod: ResponseMethod.loginrequest
        }).then((response) => {
            didLoginWithQRCode()
        }).catch((error: IBankingRightError) => {
            setError(true)
        }).finally(() => {
            setLoading(false)
        })
    }

    useInterval(() => {
        authenticationAPI?.checkLoginRequestDevicelessStatus({
            loginRequestId: loginRequestDeviceless?.loginRequestId ?? ""
        }).then((response) => {

            if (response) {
                setPolling(false)
                login(response)
            }

            setPollingCount(pollingCount + 1)

            if (pollingCount > maxPollingCount) {
                setPolling(false)
                setExpired(true)
            }

        }).catch((error) => {
            setExpired(true)
            setPolling(false)
        })
    }, polling ? 1000 * 2 : null)

    const reset = () => {
        setPolling(false)
        setExpired(false)
        setError(false)
        setPollingCount(0)
        fetchLoginRequestDeviceless()
    }

    return (
        <>
            {loading &&
                <Flex
                    boxSize={200}
                    align="center"
                    justify="center"
                    border="2px"
                    borderColor="gray.100"
                >
                    <Spinner
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='gray.200'
                        color='blue.500'
                        size='xl'
                    />
                </Flex>
            }
            {!loading &&
                <Box position="relative" boxSize="200px">
                    <Box
                        position="absolute"
                        w="100%"
                        h="100%"
                        bg={useColorModeValue("white","black")}
                        opacity={0.95}
                        border={error ? "2px" : "unset"}
                        borderColor="gray.100"
                        display={(expired || error) ? "flex" : "none"}

                    >
                        <IconButton
                            onClick={reset}
                            variant="ghost"
                            aria-label="reset"
                            left="50%"
                            top="50%"
                            transform="translate(-50%,-50%)"
                            icon={error ? <FiAlertTriangle size={35} /> : <FiRefreshCw size={35} />}
                        />
                    </Box>
                    {loginRequestDeviceless &&
                        <QRCode
                            size={200}
                            value={`loginrequest:${loginRequestDeviceless.expiresAt}:${loginRequestDeviceless.loginRequestId}`}
                        />
                    }
                </Box>
            }
        </>
    )
};