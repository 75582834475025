import { AuthProvider } from "@bankingright-web/auth-provider";
import { I18nProvider } from "@bankingright-web/core";
import { IBankingRightError } from "@bankingright-web/interfaces";
import { NotificationProvider } from "@bankingright-web/ui";
import axios, { AxiosInstance, InternalAxiosRequestConfig } from "axios";
import { AuthenticationAPI } from "../authentication";
import { PortfoliosAPI } from "../portfolios";
import { APIProvider } from "./api-provider";

interface BankingRightAPIProviderProps {
    baseUrl: string,
    httpClient?: AxiosInstance,
    isMock?: boolean
    authProvider: AuthProvider,
    notificationProvider: NotificationProvider
    i18nProvider: I18nProvider
};

const axiosInstance = axios.create();

export const BankingRightAPIProvider = ({
    baseUrl,
    httpClient = axiosInstance,
    isMock = false,
    authProvider,
    notificationProvider,
    i18nProvider
}: BankingRightAPIProviderProps): APIProvider => {

    // TODO: This code is called multiple times but it should only be called once    
    httpClient.defaults.baseURL = baseUrl

    httpClient.interceptors.response.clear()
    httpClient.interceptors.response.use(
        (response) => {
            const authHeader = response.headers.authorization
            if (authHeader && authHeader.startsWith("Bearer ")) {
                authProvider.login(authHeader.substring(7, authHeader.length))
            }

            return response;
        },
        (error) => {
            if (error.response.status === 401 && authProvider.checkAuth()) {
                notificationProvider.open({
                    message: i18nProvider.translate("general_error_authentication_failure_title","Error"),
                    description: i18nProvider.translate("general_error_authentication_failure","Your session is no longer valid, please try to login again"),
                    type: "error"
                })
                authProvider.logout()
            }

            const brError: IBankingRightError = {
                userMessage: error.response?.data?.userMessage,
                message: error.response?.data?.message,
                errorCode: error.response?.data?.errorCode,
            };

            return Promise.reject(brError);
        },
    );

    httpClient.interceptors.request.clear()
    httpClient.interceptors.request.use(        
        (request: InternalAxiosRequestConfig) => {
            const token = authProvider.token()

            // Set the Authorization header if it exists
            if (token != null) {
                request.headers["Authorization"] = `Bearer ${token}`;
            }

            return request;
        },
    );

    return {
        authenticationAPI: AuthenticationAPI(httpClient),
        portfoliosAPI: PortfoliosAPI(httpClient),
        isMock: isMock
    }
};