import { useNavigate } from "react-router-dom";
import { AuthProvider } from "./auth-provider";
import { useState } from "react"

interface BankingRightAuthProviderProps {
    redirectOnLogin?: string,
    tokenKey?: string
};

export const BankingRightAuthProvider = (
    { redirectOnLogin = "/", tokenKey = "bankingright-auth" }: BankingRightAuthProviderProps
): AuthProvider => {
    
    const navigate = useNavigate();

    return {
        login: async (token: string) => {            
            console.log("Login")
            localStorage.setItem(tokenKey, token)
            navigate(redirectOnLogin)
        },
        logout: async () => {
            console.log("Logout")
            localStorage.removeItem(tokenKey)
            navigate("/")
        },
        checkAuth: () => {
            if (localStorage.getItem(tokenKey)) {
                return true
            } else {
                return false
            }
        },
        token: () => {
            return localStorage.getItem(tokenKey)
        }
    }
};