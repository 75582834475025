import { AuthContextProvider, AuthProvider } from "@bankingright-web/auth-provider"
import { APIContextProvider, APIProvider } from "@bankingright-web/api"
import { NotificationContextProvider, NotificationProvider } from "@bankingright-web/ui"
import { I18nProvider, TranslationContextProvider } from "./translation-provider"

export interface BankingRightProps {
    notificationProvider: NotificationProvider
    apiProvider: APIProvider
    authProvider: AuthProvider
    i18nProvider: I18nProvider
    children?: React.ReactNode
}

export const BankingRight: React.FC<BankingRightProps> = ({
    notificationProvider,
    i18nProvider,
    apiProvider,
    authProvider,
    children
}) => {
    return (
        <TranslationContextProvider i18nProvider={i18nProvider}>
            <NotificationContextProvider {...notificationProvider}>
                <AuthContextProvider {...authProvider}>
                    <APIContextProvider {...apiProvider}>
                        {children}
                    </APIContextProvider>
                </AuthContextProvider>
            </NotificationContextProvider>
        </TranslationContextProvider>
    )
}