import { Box, Flex, Heading, Text, Icon, Spacer, Spinner } from "@chakra-ui/react";
import { Layout } from "@bankingright-web/layout";
import { useAPI } from "@bankingright-web/api";
import { useState, useEffect } from "react"
import { IAccount, IPortfolio } from "@bankingright-web/interfaces";
import { FiHome } from "react-icons/fi"

export const AccountOverview: React.FC = () => {

    const [loading, setLoading] = useState(false)
    const [portfolios, setPortfolios] = useState<Array<IPortfolio>>([])
    const { portfoliosAPI } = useAPI()

    useEffect(() => {
        setLoading(true)
        const fetchPortfolios = async () => {
            const data = await portfoliosAPI?.portfolios(true)
            setPortfolios(data?.portfolios ?? [])
            setLoading(false)
        }

        fetchPortfolios()
    }, [])

    const accountView: (account: IAccount, index: number) => React.ReactNode = (account, index) => {
        return (
            <Flex key={index} align="center">
                <Flex
                    bg="chakra-subtle-bg"
                    rounded="full"
                    w={10}
                    h={10}
                    align="center"
                    justify="center"
                    mr={3}
                >
                    <Icon as={FiHome} boxSize={6} />
                </Flex>
                <Flex direction="column">
                    <Heading size="md">
                        {account.name}
                    </Heading>
                    <Text>
                        {account.accountNumber}
                    </Text>

                </Flex>
                <Spacer />
                <Flex>
                    <Text>{account.currency} {account.balance}</Text>
                </Flex>
            </Flex>
        )
    }

    return (
        <Layout>
            <Box maxW="800px" mx="auto">
                <Heading size="3xl">Account Overview</Heading>
                <Flex direction="column" gap={10} mt={10}>
                    {!loading && portfolios.map((portfolio: IPortfolio, index: number) => {
                        return (
                            <Flex key={index} direction="column" gap={5}>
                                <Heading>
                                    {portfolio.name}
                                </Heading>
                                <Flex direction="column" gap={5}>
                                    {portfolio.accounts.map((account: IAccount, index: number) => {
                                        return (
                                            accountView(account, index)
                                        )
                                    })}
                                </Flex>
                            </Flex>
                        )
                    })}
                    {loading &&
                        <Flex
                            align="center"
                            justify="center"
                        >
                            <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='blue.500'
                                size='xl'
                                mt={20}
                            />
                        </Flex>
                    }
                </Flex>
            </Box>
        </Layout>
    )
};