
import { theme } from "@bankingright-web/ui";
import { Box, ColorModeScript, useColorModeValue, Text, ChakraProvider } from "@chakra-ui/react";
import React from "react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./i18n";
import reportWebVitals from "./reportWebVitals";

const container = document.getElementById("root")!
const root = createRoot(container)

const Loading: React.FC = () => {
    return (
        <Box
            bg={useColorModeValue('white', 'gray.900')}
            w="full"
            pos="fixed"
            h="full"
        >
            <Text p={5}>Loading....</Text>
        </Box>
    )
}

root.render(
    <React.StrictMode>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <ChakraProvider theme={theme}>
            <React.Suspense fallback={<Loading />}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </React.Suspense>
        </ChakraProvider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();