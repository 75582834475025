import React, { createContext, useContext } from "react";

export interface OpenNotificationParams {
    key?: string;
    message: string;
    type: "success" | "error" | "progress";
    description?: string;
    cancelMutation?: () => void;
    undoableTimeout?: number;
}

export interface INotificationContext {
    open?: (params: OpenNotificationParams) => void;
    close?: (key: string) => void;
}

export type NotificationProvider = Required<INotificationContext>;
export const NotificationContext = createContext<INotificationContext>({});

export const NotificationContextProvider: React.FC<
    INotificationContext & {
        children?: React.ReactNode;
    }
> = ({ open, close, children }) => {
    return (
        <NotificationContext.Provider value={{ open, close }}>
            {children}
        </NotificationContext.Provider>
    );
};

export const useNotification = (): INotificationContext => {
    const { open, close } = useContext(NotificationContext);

    return { open, close };
};
