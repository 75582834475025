import { useAPI } from "@bankingright-web/api";
import { useAuth } from "@bankingright-web/auth-provider";
import { VerificationCodeMethod } from "@bankingright-web/enums";
import { ILoginRequestResponse } from "@bankingright-web/interfaces";
import { useColorModeValue, Box, Button, Card, CardBody, CardHeader, Fade, Flex, Heading, IconButton, Spacer, Spinner, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { FiX } from "react-icons/fi";
import { v4 as uuidv4 } from "uuid";
import { ChooseLoginMethod } from "./choose-login-method";
import { ChooseVerificationCodeMethod } from "./choose-verification-code-method";
import { LoginRequestDebugInformation } from "./login-request-debug-information";
import { LoginRequestStatus } from "./login-request-status";
import { MessageVerificationCode } from "./message-verfication-code";
import { useNavigate } from "react-router-dom"

interface LoginProps { }

enum LoginMode {
    default,
    chooseVerificationMethod,
    verificationMethodMessage,
    verificationMethodSigningTask,
    status,
    loading
}

export const Login: React.FC<LoginProps> = ({ }) => {
    const [mode, setMode] = useState(LoginMode.default);
    const [allowedVerificationCodeMethods, setAllowedVerificationCodeMethods] = useState(Array<VerificationCodeMethod>());
    const [deviceId, setDeviceId] = useState(uuidv4());
    const [loginRequest, setLoginRequest] = useState<ILoginRequestResponse>();
    const { isOpen, onToggle } = useDisclosure()
    const { authenticationAPI } = useAPI()
    const { login } = useAuth()

    const onLoginWithCredentials = (loginRequest: ILoginRequestResponse) => {
        setAllowedVerificationCodeMethods(loginRequest?.allowedVerificationCodeMethods ?? [])
        setLoginRequest(loginRequest)
        setMode(LoginMode.chooseVerificationMethod)
    }

    const onChosenVerificationCodeMethod = (verificationCodeMethod: VerificationCodeMethod) => {
        switch (verificationCodeMethod) {
            case VerificationCodeMethod.message:
                setMode(LoginMode.verificationMethodMessage)
                return
            case VerificationCodeMethod.signingTask:
                requestSigningTaskVerificationCodeMethod()
                return
            default:
                setMode(LoginMode.default)
        }
    }

    const requestSigningTaskVerificationCodeMethod = async () => {
        setMode(LoginMode.loading)
        await authenticationAPI?.requestLoginRequestSigningTask({
            loginRequestId: loginRequest?.loginRequestId ?? ""
        })
        setMode(LoginMode.verificationMethodSigningTask)
    }

    const didProvideVerificationCode = async () => {
        setMode(LoginMode.status)
    }

    const didSuccessfullyLogin = () => {
        // The Auth Provider will be called by the API Provider
        reset()
    }

    const reset = () => {
        setLoginRequest(undefined)
        setDeviceId(uuidv4())
        setAllowedVerificationCodeMethods([])
        setMode(LoginMode.default)
    }

    const loginFlow = () => {
        switch (mode) {
            case LoginMode.default:
                return (
                    <ChooseLoginMethod
                        deviceId={deviceId}
                        didLoginWithCredentials={onLoginWithCredentials}
                        didLoginWithQRCode={didSuccessfullyLogin}
                        didDirectlyLoginWithCredentials={didSuccessfullyLogin}
                    />
                )
            case LoginMode.chooseVerificationMethod:
                return (
                    <ChooseVerificationCodeMethod
                        allowedVerificationCodeMethods={allowedVerificationCodeMethods}
                        didChooseVerificationCodeMethod={onChosenVerificationCodeMethod}
                    />
                )
            case LoginMode.verificationMethodSigningTask:
                if (loginRequest) {
                    return (
                        <LoginRequestStatus
                            loginRequestId={loginRequest.loginRequestId}
                            verificationCodeMethod={VerificationCodeMethod.signingTask}
                            didSuccessfullyLogin={didSuccessfullyLogin}
                            didFailLogin={reset}
                        />
                    )
                }
                else {
                    // TODO: show error
                    return (<Box />)
                }
            case LoginMode.verificationMethodMessage:
                if (loginRequest) {
                    return (
                        <MessageVerificationCode
                            deviceId={deviceId}
                            deliveryMethodToken={loginRequest?.deliveryMethodToken}
                            loginRequestId={loginRequest?.loginRequestId}
                            didProvideVerificationCode={didProvideVerificationCode} />
                    )
                }
                else {
                    // TODO: show error
                    return (<Box />)
                }
            case LoginMode.status:
                if (loginRequest) {
                    return (
                        <LoginRequestStatus
                            loginRequestId={loginRequest.loginRequestId}
                            verificationCodeMethod={VerificationCodeMethod.message}
                            didSuccessfullyLogin={didSuccessfullyLogin}
                            didFailLogin={reset}
                        />
                    )
                }
                else {
                    // TODO: show error
                    return (<Box />)
                }
            case LoginMode.loading:
                return (
                    <Flex
                        align="center"
                        justify="center"
                        w="100%"
                    >
                        <Spinner
                            thickness='4px'
                            speed='0.65s'
                            emptyColor='gray.200'
                            color='blue.500'
                            size='xl'
                        />
                    </Flex>
                )
        }
    }

    return (
        <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
            <Flex align="center" direction="column">
                <Card
                    bg="chakra-body-bg"
                    mt={40}
                    px={10}
                >
                    <CardHeader>
                        <Flex alignItems="center">
                            <Heading>Login</Heading>
                            <Spacer />
                            {mode != LoginMode.default &&
                                <IconButton
                                    variant='ghost'
                                    colorScheme='gray'
                                    aria-label='close'
                                    icon={<FiX size={20} />}
                                    onClick={reset}
                                />
                            }
                        </Flex>
                    </CardHeader>
                    <CardBody>
                        <Flex
                            minW="500px"
                            minH="400px"
                        >
                            {loginFlow()}
                        </Flex>
                    </CardBody>
                </Card>
                <Flex
                    direction="column"
                    w="600px"
                    mx="auto"
                    align="center"
                    mt={10}
                >
                    <Button onClick={onToggle} variant="outline">{isOpen ? "Hide" : "Show"} debug information</Button>
                    <Fade in={isOpen}>
                        <LoginRequestDebugInformation
                            deviceId={deviceId}
                            loginRequest={loginRequest}
                            loginRequestDeviceless={undefined}
                        />
                    </Fade>
                </Flex>
            </Flex>
        </Box>
    )
};