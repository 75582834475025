import { VerificationCodeMethod } from "@bankingright-web/enums";
import { Box, Flex, Heading, Text, VStack } from "@chakra-ui/react";
import { FiMail, FiSmartphone, FiAlertCircle } from "react-icons/fi"

interface ChooseVerificationCodeMethodProps {
    allowedVerificationCodeMethods: Array<VerificationCodeMethod>
    didChooseVerificationCodeMethod: (method: VerificationCodeMethod) => void
}

interface VerificationCodeMethodInfo {
    title: string
    description: string
    icon: React.ReactNode
}

export const ChooseVerificationCodeMethod: React.FC<ChooseVerificationCodeMethodProps> = ({
    allowedVerificationCodeMethods,
    didChooseVerificationCodeMethod
}) => {

    const verificationCodeInfo: (method: VerificationCodeMethod) => VerificationCodeMethodInfo = (method) => {
        switch (method) {
            case VerificationCodeMethod.message:
                return ({
                    title: "Via a message",
                    description: "Login via a message sent to your phone.",
                    icon: <FiMail size={25} />
                })
            case VerificationCodeMethod.signingTask:
                return ({
                    title: "With the mobile app",
                    description: "Login by confirming a login request on your phone",
                    icon: <FiSmartphone size={25} />
                })
            default:
                return ({
                    title: "Error",
                    description: "Something went wrong",
                    icon: <FiAlertCircle size={25} />
                })
        }
    }

    return (

        <Flex
            direction="column"
            mb={10}
            w="100%"
        >
            <Text>Please choose the verification method you would like to use.</Text>
            <VStack align="stretch" mt={5} spacing={3}>
                {allowedVerificationCodeMethods.map((method: VerificationCodeMethod, index: number) => {
                    const info = verificationCodeInfo(method)
                    return (
                        <Flex
                            key={index}
                            align="center"
                            border="2px"
                            borderColor="gray.100"
                            h="65px"
                            onClick={() => didChooseVerificationCodeMethod(method)}
                            _hover={{
                                background: "chakra-subtle-bg",
                                cursor: "pointer",
                            }}
                        >
                            <Box p={3}>
                                {info.icon}
                            </Box>
                            <Flex direction="column" ml={1}>
                                <Heading size="sm">{info.title}</Heading>
                                <Text fontSize="sm">{info.description}</Text>
                            </Flex>
                        </Flex>
                    )
                })}
            </VStack>
        </Flex>
    )
};