import { useAPI } from "@bankingright-web/api"
import { ILoginRequestDevicelessResponse, ILoginRequestResponse } from "@bankingright-web/interfaces"
import { convertToDate } from "@bankingright-web/utils"
import { VStack, Flex, Heading, Text } from "@chakra-ui/react"

interface LoginRequestDebugInformationProps {
    deviceId: string
    loginRequest?: ILoginRequestResponse
    loginRequestDeviceless?: ILoginRequestDevicelessResponse
}

export const LoginRequestDebugInformation: React.FC<LoginRequestDebugInformationProps> = ({
    deviceId,
    loginRequest,
    loginRequestDeviceless
}) => {

    const { isMock } = useAPI()

    return (
        <VStack
            spacing={5}
            p={5}
            mt={5}
            align="flex-start"
            border="2px"
            borderColor="gray.200"
        >
            <Text fontSize="sm">Device id: {deviceId}</Text>
            {loginRequest &&
                <Flex direction="column" align="flex-start">
                    <Heading size="sm">Normal</Heading>
                    <Text fontSize="sm">Login request id: {loginRequest?.loginRequestId}</Text>
                    <Text fontSize="sm">Expires at: {convertToDate(loginRequest?.expiresAt).toLocaleString()}</Text>
                </Flex>
            }
            {loginRequestDeviceless &&
                <Flex direction="column" align="flex-start">
                    <Heading size="sm">Deviceless</Heading>
                    <Text fontSize="sm">Login request id: {loginRequestDeviceless?.loginRequestId}</Text>
                    <Text fontSize="sm">Expires at: {convertToDate(loginRequestDeviceless?.expiresAt).toLocaleString()}</Text>
                </Flex>
            }
            {isMock &&
                <Text fontSize="sm">Mock environment</Text>
            }
        </VStack>
    )
}