export enum VerificationCodeMethod {
    device = "device",
    signingTask = "signingtask",
    message = "message"
}

export enum DevicePlatform {
    ios = "ios",
    android = "android",
    web = "web"
}

export enum DeviceStatus {
    pending = "pending",
    confirmed = "confirmed",
    blocked = "blocked"
}

export enum MessageDeliveryMethod {
    sms = "sms",
    whatsapp = "whatsapp",
    email = "email",
    none = "none"
} 

export enum OnboardingStatus {
    light = "light",
    full = "full",
    restricted = "restricted",
    none = "none"
}

export enum ResponseMethod {
    pin = "pin",
    biometric = "biometric",
    loginrequest = "loginrequest"
}

export enum PortfolioType {
    private = "private",
    joint = "joint",
    insight = "insight",
    business = "business",
    external = "external",
    creditcard = "creditcard",
    other = "other"
}

export enum AccountType {
    current = "current",
    savings = "savings",
    deposit = "deposit",
    investment = "investment",
    creditcard = "creditcard",
    mortgage = "mortgage",
    loan = "loan",
    unknown  = "unknown",
}

export enum AccountStatus {
    active = "active",
    userlocker = "userlocked",
    blocked = "blocked"
}