import { useAPI } from "@bankingright-web/api";
import { DevicePlatform } from "@bankingright-web/enums";
import { IBankingRightError, ILoginRequestResponse, ILoginRequestLoggedInResponse } from "@bankingright-web/interfaces";
import { useNotification } from "@bankingright-web/ui";
import { Button, FormControl, FormLabel, Input, Text, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { deviceType, browserName, osName } from 'react-device-detect';

type Inputs = {
    username: string,
    password: string,
};

interface LoginWithCredentialsProps {
    deviceId: string
    didLoginWithCredentials: (loginRequest: ILoginRequestResponse) => void
    didDirectlyLoginWithCredentials: () => void
}

export const LoginWithCredentials: React.FC<LoginWithCredentialsProps> = ({
    deviceId,
    didLoginWithCredentials,
    didDirectlyLoginWithCredentials
}) => {

    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm<Inputs>();
    const [loading, setLoading] = useState<boolean>(false)
    const { authenticationAPI } = useAPI()
    const { open } = useNotification()

    const onSubmit = (inputs: Inputs) => {
        setLoading(true)
        authenticationAPI?.createLoginRequest({
            userName: inputs.username,
            password: inputs.password,
            deviceId: deviceId,
            deviceName: browserName,
            deviceBrand: osName,
            deviceType: deviceType,
            devicePlatform: DevicePlatform.web,
            appName: "showcase",
        }).then((response) => {
            if ((response as ILoginRequestLoggedInResponse).onboardingStatus != undefined) {
                didDirectlyLoginWithCredentials()
            }
            else if ((response as ILoginRequestResponse).loginRequestId != undefined) {
                didLoginWithCredentials(response as ILoginRequestResponse)
            }
        }).catch((error: IBankingRightError) => {
            open?.({
                message: "Error",
                description: error.userMessage,
                type: "error"
            })
            reset()
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <VStack>
                <FormControl>
                    <FormLabel>Username</FormLabel>
                    <Input {...register("username", { required: true, maxLength: 40 })} type="text" />
                    {errors.username && <Text size="sm" color="red">This field is required</Text>}
                </FormControl>
                <FormControl>
                    <FormLabel>Password</FormLabel>
                    <Input {...register("password", { required: true, maxLength: 20 })} type="password" />
                    {errors.password && <Text size="sm" color="red">This field is required</Text>}
                </FormControl>
            </VStack>
            <Button mt={5} type="submit" isLoading={loading}>Sign in</Button>
        </form>
    )
};