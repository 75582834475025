import {
    Navigate
} from 'react-router-dom';
import { useAuth } from '@bankingright-web/auth-provider';

interface ProtectedRouteProps {
    children: React.ReactNode
};

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const { checkAuth } = useAuth();
    const isAuthenticated = checkAuth?.() ?? false

    if (!isAuthenticated) {
        return <Navigate to="/login" replace />;
    }

    return (
        <>
            {children}
        </>
    )
};