import React from "react";

export interface AuthProvider {
    login: (token: string) => Promise<void>;
    logout: () => Promise<void>;
    checkAuth: () => boolean;
    token: () => string | null
}

export interface IAuthContext extends Partial<AuthProvider> { }

export const AuthContext = React.createContext<IAuthContext>({});

export const AuthContextProvider: React.FC<
    IAuthContext & {
        children?: React.ReactNode;
    }
> = ({ children, ...authOperations }) => {
    return (
        <AuthContext.Provider
            value={{
                ...authOperations
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    return React.useContext(AuthContext);
};